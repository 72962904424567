@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

*{
  font-family: 'Fredoka One', sans-serif;
}

.App {
  padding: 0;
  text-align: center;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}




.App h1{
  margin: 0px;
  font-size: 50px;
}

.App h4{
  margin: 0px;
  padding: 0px;
  font-size: 25px;
}

#guess{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#guess1{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#m1{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#o1{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#m2{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#o2{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#m3{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#o3{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#m4{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#o4{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#m5{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#o5{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#m6{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#o6{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#m7{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#o7{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#m8{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#o8{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#m9{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#o9{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#m10{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}
#o10{
  text-transform: uppercase;
  &::placeholder {
    text-transform: none;
  }
}






#startBtn{
  scale: 0;
}

